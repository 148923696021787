import { Cards } from 'src/graphql/models/Cards'
import {
  FileTypes,
  PortfolioBidSummary,
  PortfolioBids,
} from 'src/infra/api/models/portfolio/portfolio-details'
import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import * as types from './types'
import { PortfolioEvents } from 'src/graphql/models/PortfolioDetail'
import {
  PortfolioTemplateFileMapResponse,
  PortfolioTemplateInfo,
} from 'src/graphql/models/PortfolioTemplates'
import { PortfoliosBeingProcessed } from 'src/graphql/models/LoadPortfolio'
import {
  BuyerInfo,
  PortfolioDisclosure,
  PortfolioFundingConfirmation,
} from 'src/graphql/models/Portfolio'
import { Paged } from 'src/data/types'
import { QueryClient } from '@tanstack/react-query'
import { UnmaskedDataInfoResponse } from '../../post/portfolio/types'

export const getPortfolioCards = async (
  userType: string | undefined,
  switchCardType: string | undefined,
  filter: any
): Promise<Cards> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioCards, {
    params: {
      userType,
      cardValueSwitchOption: switchCardType,
      filter,
    },
  })
  return response?.data
}

export const getPortfolioCardGridItems = async (
  userType: string | undefined,
  cardUrl: string | undefined,
  filter: any,
  kendoPagination: string | null,
  subFilter: any
): Promise<Paged<types.PortfolioGridItem>> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioCardGridItems,
    {
      params: {
        userType,
        cardUrl,
        filter,
        kendoPagination,
        subFilter,
      },
    }
  )
  return response?.data
}

export const getPortfolioBidsAward = async (
  portfolioId: string | undefined
): Promise<PortfolioBids> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioBidsAward, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolioBidsSummary = async (
  portfolioId: string | undefined
): Promise<PortfolioBidSummary> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioBidsSummary, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolioFilesLink = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioFilesLink, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getAllPortfolioSteps = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetAllPortfolioSteps, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolioAnalytics = async (
  portfolioId: string | undefined,
  otherData: string,
  analysisType: number | null
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioAnalytics, {
    params: {
      portfolioId,
      otherData,
      analysisType,
    },
  })
  return response?.data
}

export const getPortfolioPurchaseInfo = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioPurchaseInfo,
    {
      params: {
        portfolioId,
      },
    }
  )
  return response?.data
}

export const getPsaStatus = async (
  portfolioId: string | undefined,
  includeBlobSnapshot: boolean | undefined,
  includeAddendumHistory: boolean | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetPsaStatus, {
    params: {
      portfolioId,
      includeBlobSnapshot,
      includeAddendumHistory,
    },
  })
  return response?.data
}

export const getPortfolioDisclosure = async (
  portfolioId: string | undefined
): Promise<PortfolioDisclosure> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioDisclosure, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getAdditionalUnmaskedDataRequests = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetAdditionalUnmaskedDataRequests,
    {
      params: {
        portfolioId,
      },
    }
  )
  return response?.data
}

export const getPortfolioBuyersByBuyerId = async (): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioBuyersByBuyerId
  )
  return response?.data
}

export const getPortfolioType = async (
  portfolioNumber: number | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioType, {
    params: {
      portfolioNumber,
    },
  })
  return response?.data
}

export const getMyPortfoliosSellers = async (): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetMyPortfoliosSellers)
  return response?.data
}

export const getBKPortfolioBalance = async (
  buyerId: string | undefined,
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetBkPortfolioBalance, {
    params: {
      buyerId,
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolioCollectionActivitySummary = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioCollectionActivitySummary,
    {
      params: {
        portfolioId,
      },
    }
  )
  return response?.data
}

export const getPortfolioMediaFilesLink = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioMediaFilesLink,
    {
      params: {
        portfolioId,
      },
    }
  )
  return response?.data
}

export const getUnmaskDataInfo = async (
  portfolioId: string | undefined
): Promise<UnmaskedDataInfoResponse> => {
  const response = await httpClient.get(urls.PortfolioGetUnmaskDataInfo, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolio = async (
  portfolioId?: string | undefined,
  portfolioNumber?: Number | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolio, {
    params: {
      portfolioId: portfolioId,
      portfolioNumber: portfolioNumber,
    },
  })
  return response?.data
}

export const getPortfolioFundingConfirmation = async (
  portfolioId?: string | undefined
): Promise<PortfolioFundingConfirmation> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioFundingConfirmation,
    {
      params: {
        portfolioId: portfolioId,
      },
    }
  )
  return response?.data
}

export const getPortfolioEvents = async (
  portfolioId: string | undefined,
  kendoPagination: string | undefined,
  quickFilter: string | undefined
): Promise<PortfolioEvents> => {
  const response = await httpClient.get(urls.PortfolioEvents, {
    params: {
      portfolioId,
      kendoPagination,
      quickFilter,
    },
  })
  return response?.data
}

export const getPortfolioTemplate = async (
  portfolioTemplateId: number | undefined
): Promise<PortfolioTemplateInfo> => {
  const response = await httpClient.get(urls.PortfolioTemplate, {
    params: {
      portfolioTemplateId,
    },
  })
  return response?.data
}

export const refetchAllPortfolioSteps = async (queryClient: QueryClient) => {
  queryClient.refetchQueries(['GetAllPortfolioSteps'])
}

export const getPortfoliosBeingProcessed = async (): Promise<
  PortfoliosBeingProcessed[]
> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfoliosBeingProcessed
  )
  return response?.data
}

export const getBuyersAllowedAccessPortfolio = async (
  portfolioId?: string | undefined
): Promise<BuyerInfo[]> => {
  const response = await httpClient.get(
    urls.PortfolioGetBuyersAllowedAccessPortfolio,
    {
      params: {
        portfolioId: portfolioId,
      },
    }
  )
  return response?.data
}

export const getPortfolioBuyerBids = async (
  portfolioId: string | undefined
): Promise<types.PortfolioBid[]> => {
  const response = await httpClient.get(urls.PortfolioGetBuyerBids, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolioInvoiceFilesLink = async (
  portfolioId: string | undefined
): Promise<types.PortfolioFileLink[]> => {
  const response = await httpClient.get(urls.PortfolioGetInvoceFilesLink, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getFileTypeList = async (): Promise<FileTypes[]> => {
  const response = await httpClient.get(urls.PortfolioGetFileTypeList)
  return response?.data
}

export const getPortfolioAccounts = async (
  portfolioId: string | undefined,
  accountType: string | undefined,
  kendoPagination: string | undefined
): Promise<types.PortfolioDetailAccounts> => {
  const response = await httpClient.get(urls.PortfolioGetAccounts, {
    params: {
      portfolioId,
      accountType,
      kendoPagination,
    },
  })
  return response?.data
}

export const getPortfolioTemplateBySeller = async (
  sellerId: string | undefined,
  portfolioTemplateStatus: string | undefined,
  templateName: string | undefined | null,
  kendoPagination: string | undefined
): Promise<types.PortfolioTemplate> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioTemplateBySeller,
    {
      params: {
        sellerId,
        portfolioTemplateStatus,
        templateName,
        kendoPagination,
      },
    }
  )
  return response?.data
}

export const getManagePortfolioAccountsByLenderTemplateFile =
  async (): Promise<any> => {
    const response = await httpClient.get(
      urls.PortfolioGetManageAccountsByLenderTemplateFile,
      {}
    )
    return response?.data
  }

export const getManifestTemplateFile = async (): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetManifestTemplateFile,
    {}
  )
  return response?.data
}

export const getPortfolioTemplateFileMap = async (
  portfolioTemplateId: number | undefined
): Promise<PortfolioTemplateFileMapResponse> => {
  const response = await httpClient.get(urls.PortfolioTemplateFileMap, {
    params: {
      portfolioTemplateId,
    },
  })
  return response?.data
}

export const getNewPortfolioTemplateOptions = async (
  sellerId: string | undefined,
  forwardFlowAgreementId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetNewPortfolioTemplateOptions,
    {
      params: {
        sellerId,
        forwardFlowAgreementId,
      },
    }
  )
  return response?.data
}

export const getSellerInfo = async (
  sellerId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetSellerInfo, {
    params: {
      sellerId,
    },
  })
  return response?.data
}

export const getAllSellersInfo = async (): Promise<types.SellerList[]> => {
  const response = await httpClient.get(urls.PortfolioGetAllSellersInfo)
  return response?.data
}

export const getPortfolioFileUri = async (
  portfolioId: string | undefined,
  fileType: string | undefined,
  snapshot?: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfoliGetPortfolioFileUri, {
    params: {
      portfolioId,
      fileType,
      snapshot,
    },
  })
  return response?.data
}

export const getAuction = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetAuction, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolioAuctionBuyer = async (
  portfolioId: string | undefined,
  gridState: any
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioAuctionBuyer,
    {
      params: {
        portfolioId,
        kendoPagination: JSON.stringify(gridState),
      },
    }
  )
  return response?.data
}

export const getAvailableAuctionBuyers = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetAvailableAuctionBuyers,
    {
      params: {
        portfolioId,
      },
    }
  )
  return response?.data
}

export const getBidTypesList = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetBidTypesList, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getFundingInformation = async (
  portfolioId: string | undefined,
  fundingType: string
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetFundingInformation, {
    params: {
      portfolioId,
      fundingType,
    },
  })
  return response?.data
}

export const getPortfolioBidFileUriApi = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioBidFileUri, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolioBidPsaFileUriApi = async (
  bidId: number | undefined,
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioBidPsaFileUri,
    {
      params: {
        bidId,
        portfolioId,
      },
    }
  )
  return response?.data
}

export const getPortfolioListRequest = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetListRequest, {
    params: {
      portfolioId,
    },
  })
  return response?.data
}

export const getPortfolioSurveyAnswers = async (
  portfolioId: string | undefined,
  surveyId: number | undefined,
  businessId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PortfolioGetPortfolioSurveyAnswers,
    {
      params: {
        portfolioId,
        surveyId,
        businessId,
      },
    }
  )
  return response?.data
}
export const getPortfolioErrorMsg = async (
  portfolioRowErrorId: any,
  errorType: string,
  rowIndex: any
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetPortfolioErrorMsg, {
    params: {
      portfolioRowErrorId,
      errorType,
      rowIndex,
    },
  })
  return response?.data
}

export const getPortfolioAssetTypes = async (
  allowsSellers: boolean,
  portfolioTypeId: number | null,
  country: String | null
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetAssetTypes, {
    params: {
      allowsSellers,
      portfolioTypeId,
      country,
    },
  })
  return response?.data
}

export const getPortfolioGetBankAccount = async (
  buyerId: string | null
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetBankAccount, {
    params: {
      buyerId,
    },
  })
  return response
}

export const getPortfolioGetMediaUploadBulk = async (
  portfolioId: string | null
): Promise<any> => {
  const response = await httpClient.get(urls.getPortfolioGetMediaUploadBulk, {
    params: {
      portfolioId,
    },
  })
  return response
}

export const getAccountCardMediaFilesLink = async (
  portfolioId: string | undefined,
  portfolioRowGuid: string | undefined,
  lenderLoanId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.GetAccountCardMediaFilesLinkURL, {
    params: {
      portfolioId,
      portfolioRowGuid,
      lenderLoanId,
    },
  })
  return response
}

export const getPortfolioBid = async (data: any): Promise<any> => {
  const response = await httpClient.get(urls.GetPortfolioBid, {
    params: data,
  })
  return response?.data
}

export const refetchGetAccountCardMediaFilesLink = async (
  queryClient: QueryClient
) => {
  queryClient.refetchQueries(['getAccountCardMediaFilesLink'])
}

export const getApprovedNDABySellerAndBuyer = async (
  workflowId: number
): Promise<types.ApprovedNDABySellerAndBuyerResponse[]> => {
  const response = await httpClient.get(
    urls.PortfolioGetApprovedNDABySellerAndBuyer,
    {
      params: { workflowId },
    }
  )
  return response?.data
}

export const getNDADocumentUriByFile = async (
  workflowId: number,
  unmaskedDataFileId: number
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioGetNDADocumentUriByFile, {
    params: { workflowId, unmaskedDataFileId },
  })
  return response?.data
}

export const getUnmaskedDataWorkflowLog = async (
  workflowId: number
): Promise<types.WorkflowLogResponse[]> => {
  const response = await httpClient.get(
    urls.PortfolioGetUnmaskedDataWorkflowLog,
    {
      params: { workflowId },
    }
  )
  return response?.data
}
