/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { Typography } from '@mui/material'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ModalDialog } from 'everchain-uilibrary'
import { useQueryClient } from '@tanstack/react-query'
import { approveUnmaskedDataRequest } from 'src/data/features/post/portfolio/portfolio'

interface ApproveUnmaskedRequestModalProps {
  workflowId?: number
  currentState?: string
  setDisableUI: (disabled: boolean) => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const ApproveUnmaskedRequestModal: React.FC<
  ApproveUnmaskedRequestModalProps
> = ({
  workflowId,
  currentState,
  setDisableUI,
  isOpen,
  setIsOpen,
}: ApproveUnmaskedRequestModalProps) => {
  const [approveUnmaskedWorkflowId, setApproveUnmaskedWorkflowId] =
    useState<number>()

  const { enqueueSnackbar } = useSnackbar()
  const reactQueryClient = useQueryClient()

  const { isFetching: loading } = useCustomQuery<any>(
    ['approveUnmaskedDataRequest', approveUnmaskedWorkflowId],
    async () => {
      if (approveUnmaskedWorkflowId) {
        await approveUnmaskedDataRequest(approveUnmaskedWorkflowId)
          .then(() => {
            enqueueSnackbar('Request approved', notifySuccess)
            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
            setIsOpen(false)
          })
          .catch(() => {
            enqueueSnackbar('Approval failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setApproveUnmaskedWorkflowId(undefined)
          })
      }
    },
    { enabled: !!approveUnmaskedWorkflowId }
  )

  const ndaNotApproved =
    currentState !== 'Pending Approval' &&
    currentState !== 'Pending Seller Signature'

  return (
    <ModalDialog
      header="Approve Unmasked Data Request"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
        setDisableUI(false)
      }}
      onContinue={() => setApproveUnmaskedWorkflowId(workflowId)}
      buttonOkText="Confirm"
      isFetching={loading}
      maxwidth="60%"
    >
      {ndaNotApproved && (
        <Typography pb={2}>
          The NDA draft has not been generated yet. You may proceed without an
          NDA, but please confirm that you acknowledge this and agree to release
          the unmasked data file without an NDA in place.
        </Typography>
      )}
      <Typography>
        If you confirm, the unmasked data file will be released to the buyer.
      </Typography>
    </ModalDialog>
  )
}

export default ApproveUnmaskedRequestModal
