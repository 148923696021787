/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useCallback, useContext, useState } from 'react'
import {
  AdditionalUnmaskedDataRequests,
  UnmaskDataActivities,
} from 'src/graphql/models/Portfolio'
import { ConvertDateToUTC, MomentDateFormat } from 'src/utils/date'
import { Box, IconButton, Typography, Skeleton } from '@mui/material'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { AuthContext } from 'src/context/AuthenticationContext'
import DTAccordion from 'src/components/Accordion'
import { INTERNAL, PortfolioStatusType } from 'src/utils/constants'
import moment from 'moment'

import { DropUpload, UploadItem } from 'src/components/UploadDragDrop/styles'
import { useDropzone } from 'react-dropzone'
import {
  Colors,
  DataTable,
  headerActions,
  Icon,
  ModalDialog,
  renderCellTableActions,
  textSecondary,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getAdditionalUnmaskedDataRequests } from 'src/data/features/get/portfolio/portfolio'
import {
  requestNDAUnmaskedData,
  uploadNDAFile,
} from 'src/data/features/post/portfolio/portfolio'
import { useQueryClient } from '@tanstack/react-query'
import { AgreementTemplateFeatureFlagResponse } from 'src/data/features/get/agreementTemplate/types'
import { getFeatureFlagAgreementTemplate } from 'src/data/features/get/agreementTemplate/agreementTemplate'
import { useLazyQuery } from '@apollo/client'
import { GET_DOCUMENT_NDA_URI } from 'src/graphql/operations/queries/portfolio'
import { NDADocumentUri } from 'src/graphql/models/PortfolioDetail'
import { UploadNDAFileRequest } from 'src/data/features/post/portfolio/types'
import ApprovedNDABySellerAndBuyerModal from './ApprovedNDABySellerAndBuyerModal'
import ApproveNDADraftModal from './ApproveNDADraftModal'
import ApproveUnmaskedRequestModal from './ApproveUnmaskedRequestModal'
import DenyUnmaskedRequestModal from './DenyUnmaskedRequestModal'
import UnmaskedDataNDAFilesModal from './UnmaskedDataNDAFilesModal'
import UnmaskedDataHistoryModal from './UnmaskedDataHistoryModal'

interface ApproveUnmaskedDataRequestProps {
  portfolioId: string
  portfolioStatus: string
}

const ApproveUnmaskedDataRequest: React.FC<ApproveUnmaskedDataRequestProps> = ({
  portfolioId,
  portfolioStatus,
}: ApproveUnmaskedDataRequestProps) => {
  const MAX_SIZE_FILE = 104857600 // 100MB
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { userPermissions } = useContext(AuthContext)
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const [openApprovedNDAModal, setOpenApprovedNDAModal] =
    useState<boolean>(false)
  const [openApproveNDADraftModal, setOpenApproveNDADraftModal] =
    useState<boolean>(false)
  const [openApproveRequestModal, setOpenApproveRequestModal] =
    useState<boolean>(false)
  const [openDenyRequestModal, setOpenDenyRequestModal] =
    useState<boolean>(false)
  const [file, setFile] = useState<any>(null)
  const [approvedNDAModalWorkflowId, setApprovedNDAModalWorkflowId] =
    useState<any>('')
  const [currentWorkflowId, setCurrentWorkflowId] = useState<any>('')
  const [currentState, setCurrentState] = useState<string>('')
  const [requestNDAWorkflowId, setRequestNDAWorkflowId] = useState<number>()
  const [approveUnmaskedWorkflowId, setApproveUnmaskedWorkflowId] =
    useState<number>()
  const [denyUnmaskedWorkflowId, setDenyUnmaskedWorkflowId] = useState<number>()
  const [approveNDADraftWorkflowId, setApproveNDADraftWorkflowId] =
    useState<number>()
  const [uploadNDAFileRequest, setUploadNDAFileRequest] =
    useState<UploadNDAFileRequest>()
  const [filesModalWorkflow, setFilesModalWorkflow] = useState<any>()
  const [openFilesModal, setOpenFilesModal] = useState<boolean>(false)
  const [historyModalWorkflow, setHistoryModalWorkflow] = useState<any>()
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false)

  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0])
  }, [])
  const reactQueryClient = useQueryClient()

  const { data: requestsData, isFetching: loadingRequests } =
    useCustomQuery<AdditionalUnmaskedDataRequests>(
      ['getAdditionalUnmaskedDataRequests', portfolioId],
      async () => getAdditionalUnmaskedDataRequests(portfolioId),
      { enabled: true, cacheTime: 0 }
    )

  const { isFetching: fileUploading } = useCustomQuery<any>(
    ['uploadNDAFile', uploadNDAFileRequest],
    async () => {
      if (uploadNDAFileRequest) {
        await uploadNDAFile(uploadNDAFileRequest)
          .then(() => {
            setFile(null)
            setOpenUploadModal(false)
            enqueueSnackbar('File uploaded successfully', notifySuccess)

            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
          })
          .catch(() => {
            enqueueSnackbar(
              'Error while trying to upload the file',
              notifyError
            )
          })
          .finally(() => {
            setUploadNDAFileRequest(undefined)
          })
      }
    },
    { enabled: !!uploadNDAFileRequest }
  )

  const [getUri] = useLazyQuery<NDADocumentUri>(GET_DOCUMENT_NDA_URI, {
    onCompleted: (data) => {
      const uri = data?.getNDADocumentUri ?? ''
      if (uri === '') return
      window.location.href = uri
    },
  })

  const { data: featureFlagAgreementTemplate } =
    useCustomQuery<AgreementTemplateFeatureFlagResponse>(
      ['getFeatureFlagAgreementTemplate', portfolioId],
      async () => getFeatureFlagAgreementTemplate(portfolioId),
      {
        cacheTime: 0,
        enabled: true,
      }
    )

  const { isFetching: ndaUnmaskLoading } = useCustomQuery<any>(
    ['requestNDAUnmaskedData', requestNDAWorkflowId],
    async () => {
      if (requestNDAWorkflowId) {
        await requestNDAUnmaskedData(requestNDAWorkflowId)
          .then(() => {
            enqueueSnackbar('NDA Requested', notifySuccess)
            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
          })
          .catch(() => {
            enqueueSnackbar('NDA Request failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setRequestNDAWorkflowId(undefined)
          })
      }
    },
    { enabled: !!requestNDAWorkflowId }
  )

  const newNDAEnabled = featureFlagAgreementTemplate?.enableNDAAgreementTemplate
  const isNDADraft = currentState === 'Pending Seller Review' && newNDAEnabled

  const fileAccept = isNDADraft
    ? '.docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
    : '.pdf, application/pdf'

  const ndaFileDescription = isNDADraft
    ? 'NDA Draft File (*.docx)'
    : 'Executed NDA File (*.pdf)'

  const ndaUploadDescription = isNDADraft
    ? 'Upload NDA Draft'
    : 'Upload executed NDA'

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileAccept,
    multiple: false,
    minSize: 500,
    maxSize: MAX_SIZE_FILE,
  })

  const { profileClient } = useContext(AuthContext)
  const momentFormat = MomentDateFormat(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const handleFileUpload = () => {
    if (file) {
      setUploadNDAFileRequest({
        workflowId: currentWorkflowId,
        file,
        fileType: isNDADraft ? 'NDADraft' : 'NDAFinal',
      })
    }
  }

  const getFinalFileUri = (dataItem: UnmaskDataActivities) => {
    return (
      dataItem?.ndaBlobInternalUri ||
      dataItem?.ndaBlobSellerUri ||
      dataItem?.ndaBlobBuyerUri
    )
  }

  const actionsMenuInternalOrClosed = (dataItem: UnmaskDataActivities) => {
    const downloadNDAAction = getFinalFileUri(dataItem)
      ? [
          {
            name: 'Download the NDA',
            onClick: () => {
              const uri = getFinalFileUri(dataItem)

              if (uri) window.location.href = uri
            },
            loading: disableUI,
          },
        ]
      : []

    const uploadNDAAction =
      userPermissions.type === INTERNAL &&
      requestsData?.canShowNDARequest &&
      (dataItem.approval || dataItem.executed)
        ? [
            {
              name: 'Upload the NDA',
              onClick: () => {
                setOpenUploadModal(true)
                setCurrentWorkflowId(dataItem.workflowId)
                setCurrentState(dataItem.currentState)
              },
              loading: disableUI,
            },
          ]
        : []

    return [...downloadNDAAction, ...uploadNDAAction]
  }

  const actionsMenu = (props: any) => {
    const { dataItem } = props

    const ndaVersionsAction =
      requestsData?.unmaskDataActivities[0]?.activityUnmaskDataFile &&
      requestsData.unmaskDataActivities[0].activityUnmaskDataFile.length > 0
        ? [
            {
              name:
                userPermissions.type === INTERNAL &&
                !(dataItem.approval || dataItem.denial || dataItem.executed)
                  ? 'NDA Versions/Rewind'
                  : 'NDA Versions',
              onClick: () => {
                setFilesModalWorkflow(dataItem)
                setOpenFilesModal(true)
              },
              loading: disableUI,
            },
          ]
        : []

    const historyAcion = [
      {
        name: 'History',
        onClick: () => {
          setHistoryModalWorkflow(dataItem)
          setOpenHistoryModal(true)
        },
        loading: disableUI,
      },
    ]

    if (
      userPermissions.type === INTERNAL ||
      dataItem.approval ||
      dataItem.denial ||
      dataItem.executed
    ) {
      const actionsInternalOrClosed = actionsMenuInternalOrClosed(dataItem)
      return [...actionsInternalOrClosed, ...ndaVersionsAction, ...historyAcion]
    }

    const approveRequestAction = [
      {
        name: 'Approve Request',
        onClick: () => {
          setDisableUI(true)
          setApproveUnmaskedWorkflowId(dataItem.workflowId)
          setCurrentState(dataItem.currentState)
          setOpenApproveRequestModal(true)
        },
        loading: disableUI,
      },
    ]

    const rejectRequestAction = [
      {
        name: 'Reject Request',
        onClick: () => {
          setDisableUI(true)
          setDenyUnmaskedWorkflowId(dataItem.workflowId)
          setOpenDenyRequestModal(true)
        },
        loading: disableUI,
      },
    ]

    const approveNDADraftAction =
      requestsData?.canShowNDARequest &&
      newNDAEnabled &&
      dataItem.currentState === 'Pending Seller Review'
        ? [
            {
              name: 'Approve NDA Draft',
              onClick: () => {
                setDisableUI(true)
                setApproveNDADraftWorkflowId(dataItem.workflowId)
                setOpenApproveNDADraftModal(true)
              },
              loading: disableUI || ndaUnmaskLoading,
            },
          ]
        : []

    const requestNDAAction =
      requestsData?.canShowNDARequest && !dataItem.ndaUnmaskDataRequested
        ? [
            {
              name: 'Request NDA',
              onClick: () => {
                setDisableUI(true)
                setRequestNDAWorkflowId(dataItem.workflowId)
              },
              loading: disableUI,
            },
          ]
        : []

    const downloadNDADraftAction =
      requestsData?.canShowNDARequest &&
      newNDAEnabled &&
      dataItem.currentState === 'Pending Seller Review'
        ? [
            {
              name: 'Download the NDA Draft',
              onClick: () => {
                getUri({ variables: { workflowId: dataItem.workflowId } })
              },
              loading: disableUI,
            },
          ]
        : []

    const downloadBuyerNDAAction =
      requestsData?.canShowNDARequest && dataItem.ndaBlobBuyerUri
        ? [
            {
              name: dataItem.ndaBlobSellerUri
                ? 'Download the Final Executed NDA'
                : 'Download the Buyer Executed NDA',
              onClick: () => {
                const uri = getFinalFileUri(dataItem)
                if (uri) window.location.href = uri
              },
              loading: disableUI,
            },
          ]
        : []

    const uploadNDAAction =
      requestsData?.canShowNDARequest &&
      (dataItem.ndaBlobBuyerUri || dataItem.ndaDraftBlobBuyerUri) &&
      (!newNDAEnabled ||
        dataItem.currentState === 'Pending Seller Review' ||
        dataItem.currentState === 'Pending Seller Signature')
        ? [
            {
              name:
                dataItem.currentState === 'Pending Seller Review'
                  ? 'Upload NDA Draft'
                  : 'Upload Executed NDA',
              onClick: () => {
                setOpenUploadModal(true)
                setCurrentWorkflowId(dataItem.workflowId)
                setCurrentState(dataItem.currentState)
              },
              loading: disableUI,
            },
          ]
        : []

    const listApprovedNDAsAction = requestsData?.canShowNDARequest
      ? [
          {
            name: 'Display Approved NDAs',
            onClick: () => {
              setApprovedNDAModalWorkflowId(dataItem.workflowId)
              setOpenApprovedNDAModal(true)
            },
            loading: disableUI,
          },
        ]
      : []

    return [
      ...approveRequestAction,
      ...rejectRequestAction,
      ...approveNDADraftAction,
      ...requestNDAAction,
      ...downloadNDADraftAction,
      ...downloadBuyerNDAAction,
      ...uploadNDAAction,
      ...listApprovedNDAsAction,
      ...ndaVersionsAction,
      ...historyAcion,
    ]
  }

  const gridColumns: any[] = [
    {
      title: 'Business Name',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>{props.dataItem.request.performedByBusinessName}</td>
        )
      },
    },
    {
      field: 'currentState',
      title: 'Status',
      show: true,
    },
    {
      title: 'Request Date',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>
            {props.dataItem.request.performedOn
              ? moment(
                  new Date(ConvertDateToUTC(props.dataItem.request.performedOn))
                )?.format(momentFormat)
              : 'N/A'}
          </td>
        )
      },
    },
    {
      title: 'Actions',
      show: true,
      width: '60px',
      headerCell: headerActions,
      render: (props: any) => renderCellTableActions(props, actionsMenu(props)),
    },
  ]

  if (loadingRequests) {
    return (
      <Box mt={5}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <DTAccordion
      id="unmasked-data-accordion"
      title="Unmasked Data"
      icon={<Icon name="VisibilityOff" />}
      expanded={portfolioStatus === PortfolioStatusType.Listed}
    >
      {!requestsData?.templateHasUnmaskedColumns && (
        <Box pb={2}>
          <Typography variant="body2" color={Colors.error}>
            There are no available unmasked fields set up.
          </Typography>
        </Box>
      )}
      <Box style={{ height: '100%', width: '100%' }}>
        <DataTable
          style={{ height: '100%', width: '99%' }}
          data={requestsData?.unmaskDataActivities}
          gridColumns={gridColumns}
        />
      </Box>

      <ModalDialog
        isOpen={openUploadModal}
        header={ndaUploadDescription}
        onClose={() => {
          setOpenUploadModal(false)
          setCurrentWorkflowId('')
          setCurrentState('')
        }}
        onContinue={handleFileUpload}
        isFetching={fileUploading}
        buttonOkText="Upload File"
        disableOkButton={disableUI || !file || fileUploading}
        width="50%"
      >
        <Box display="flex" flexDirection="column">
          <Box>
            {!file ? (
              <DropUpload
                isDragActive={isDragActive}
                {...getRootProps()}
                disabled={disableUI}
              >
                <input {...getInputProps()} />
                <div className="upload-placeholder">
                  <Icon name="CloudUpload" className="upload-icon" />{' '}
                  <strong style={{ cursor: 'pointer' }}>Browse </strong> or Drop
                  file here to upload
                </div>
                <div className="upload-placeholder ">
                  <div className="upload-placeholder-padding">
                    {ndaFileDescription}
                  </div>
                </div>
              </DropUpload>
            ) : (
              <UploadItem>
                <div className="upload-item-info">
                  <Icon
                    name="Description"
                    fontSize="small"
                    color="primary"
                    className="upload-item-icon"
                  />
                  <Typography
                    variant="body2"
                    color={textSecondary.color}
                    component="span"
                    key={file.name}
                  >
                    {file.name}
                  </Typography>
                </div>
                <IconButton
                  aria-label="Clear file selection"
                  onClick={() => setFile(null)}
                  disabled={disableUI}
                >
                  <Icon name="Delete" fontSize="small" />
                </IconButton>
              </UploadItem>
            )}
          </Box>
        </Box>
      </ModalDialog>
      <ApprovedNDABySellerAndBuyerModal
        openModal={openApprovedNDAModal}
        setOpenModal={setOpenApprovedNDAModal}
        workflowId={approvedNDAModalWorkflowId}
      />
      <ApproveNDADraftModal
        isOpen={openApproveNDADraftModal}
        setIsOpen={setOpenApproveNDADraftModal}
        refetchQueries={['getAdditionalUnmaskedDataRequests']}
        workflowId={approveNDADraftWorkflowId}
        setDisableUI={setDisableUI}
      />
      <ApproveUnmaskedRequestModal
        isOpen={openApproveRequestModal}
        setIsOpen={setOpenApproveRequestModal}
        workflowId={approveUnmaskedWorkflowId}
        setDisableUI={setDisableUI}
        currentState={currentState}
      />
      <DenyUnmaskedRequestModal
        isOpen={openDenyRequestModal}
        setIsOpen={setOpenDenyRequestModal}
        workflowId={denyUnmaskedWorkflowId}
        setDisableUI={setDisableUI}
      />
      <UnmaskedDataNDAFilesModal
        isOpen={openFilesModal}
        setIsOpen={setOpenFilesModal}
        workflowId={filesModalWorkflow?.workflowId}
        activityUnmaskDataFiles={filesModalWorkflow?.activityUnmaskDataFile}
        refetchQueries={['getAdditionalUnmaskedDataRequests']}
        unmaskDataActivities={filesModalWorkflow}
      />
      <UnmaskedDataHistoryModal
        isOpen={openHistoryModal}
        setIsOpen={setOpenHistoryModal}
        workflowId={historyModalWorkflow?.workflowId}
      />
    </DTAccordion>
  )
}

export default ApproveUnmaskedDataRequest
