import { Tooltip, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface CustomTooltip {
  children: React.ReactElement<any, any>
  title: React.ReactNode
  enterTouchDelay: number
  leaveTouchDelay: number
  placement:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | undefined
}

const tooltipStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 1200,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '10px',
  },
}))

export const CustomToolTip: React.FC<CustomTooltip> = ({
  children,
  title,
  ...rest
}) => {
  const classes = tooltipStyles()

  return (
    <Tooltip title={title} className={classes.tooltip} {...rest}>
      {children}
    </Tooltip>
  )
}
