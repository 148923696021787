/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-newline */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { portfolioMutation } from 'src/graphql/operations/mutations'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  Colors,
  CurrencyInput,
  DatePicker,
  fCalcNumberToPercent,
  fCalcPercentToNumber,
  Flex,
  fNumberCurrency,
  Icon,
} from 'everchain-uilibrary'
import moment from 'moment'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useQueryClient } from '@tanstack/react-query'
import { splitPortfolio } from 'src/data/features/post/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  PortfolioSplitDateRangeEnum,
  PortfolioSplitRequest,
  SplitAccountBreakupParameterEnum,
  SplitAccountBreakupTypeEnum,
} from 'src/data/features/post/portfolio/types'
import SimulateSplitPortfolio from './SimulateSplitPortfolio'

export interface SplitOption {
  templateId: number
  fromDate?: Date | null
  toDate?: Date | null
  percentage?: number
  totalAmount?: number
}

enum SplitTypesEnum {
  Random = 'Random',
  Date = 'Date',
}

const splitTypes = [
  { id: SplitTypesEnum.Random, name: 'Randomize accounts' },
  { id: SplitTypesEnum.Date, name: 'By Date' },
]

const splitDataRangeTypes = [
  { id: PortfolioSplitDateRangeEnum.Custom, name: 'By Date Range' },
  { id: PortfolioSplitDateRangeEnum.ByMonth, name: 'By Month' },
  { id: PortfolioSplitDateRangeEnum.ByQuarter, name: 'By Quarter' },
  { id: PortfolioSplitDateRangeEnum.ByYear, name: 'By Year' },
]

const dateSplitTypes = [
  { id: 'DefaultDate', name: 'Default Date' },
  { id: 'WriteOffDate', name: 'Write Off Date' },
  { id: 'OriginationDate', name: 'Origination Date' },
]

const splitAccountBreakupParameterTypes = [
  { id: SplitAccountBreakupParameterEnum.FaceValue, name: 'Face Value' },
  {
    id: SplitAccountBreakupParameterEnum.NumberOfAccounts,
    name: 'Number of Accounts',
  },
]

export const splitAccountBreakupTypes = [
  { id: SplitAccountBreakupTypeEnum.Percentage, name: 'Percentage' },
  { id: SplitAccountBreakupTypeEnum.TotalAmount, name: 'Total Amount' },
]

interface SplitPortfolioProps {
  assetTypes: string
  fileName: string
  totalNumberOfAccounts: number
  selectedTemplateId: number
  stagingId: string
  sellerId: string
  templates: any
  cutOffDate: Date | null
  totalFaceValue: number
  forwardFlowMaxFaceValue?: number
  setOpenSplitDialog?: (open: boolean) => void
}

const SplitPortfolio: React.FC<SplitPortfolioProps> = ({
  assetTypes,
  fileName,
  totalNumberOfAccounts,
  selectedTemplateId,
  stagingId,
  sellerId,
  templates,
  cutOffDate,
  totalFaceValue,
  forwardFlowMaxFaceValue,
  setOpenSplitDialog = () => {},
}: SplitPortfolioProps) => {
  const [numberOfSplits, setNumberOfSplits] = useState(2)
  const [splitOptions, setSplitOptions] = useState<SplitOption[]>([
    { templateId: selectedTemplateId },
    { templateId: selectedTemplateId },
  ])
  const [splitType, setSplitType] = useState<SplitTypesEnum>(
    SplitTypesEnum.Random
  )
  const [splitDateRange, setSplitDateRange] =
    useState<PortfolioSplitDateRangeEnum>(PortfolioSplitDateRangeEnum.Custom)
  const [splitAccountBreakupParameter, setSplitAccountBreakupParameter] =
    useState<SplitAccountBreakupParameterEnum>(
      SplitAccountBreakupParameterEnum.FaceValue
    )
  const [splitAccountBreakupType, setSplitAccountBreakupType] =
    useState<SplitAccountBreakupTypeEnum>(
      SplitAccountBreakupTypeEnum.Percentage
    )
  const [templateId, setTemplateId] = useState<number>(selectedTemplateId)
  const [splitDateType, setSplitDateType] = useState<string>(
    dateSplitTypes[0].id
  )
  const [portfolioSplitRequest, setPortfolioSplitRequest] =
    useState<PortfolioSplitRequest>()
  const [simulateSplitRequest, setSimulateSplitRequest] =
    useState<PortfolioSplitRequest>()

  const [showSimulateSplit, setShowSimulateSplit] = useState<boolean>(false)
  const { profileClient } = useContext(AuthContext)

  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const reactQueryClient = useQueryClient()

  const { useTeardownStagePortfolioSplit } = portfolioMutation

  const {
    teardownStagePortfolioSplit,
    loading: loadingTeardownStagePortfolioSplit,
  } = useTeardownStagePortfolioSplit({
    onCompleted: () => {
      setOpenSplitDialog(false)
      enqueueSnackbar('Portfolio Split cancelled successfully', notifySuccess)
    },
    onError: () => {
      setOpenSplitDialog(false)
      enqueueSnackbar('Failed to cancel the portfolio split', notifyError)
    },
  })

  const handleCancel = () => {
    teardownStagePortfolioSplit({
      variables: {
        stagingId,
        sellerId,
        fileName,
      },
    })
  }

  const { isFetching: loadingSplitPortfolio } = useCustomQuery<any>(
    ['splitPortfolio', portfolioSplitRequest],
    async () => {
      if (portfolioSplitRequest) {
        await splitPortfolio(portfolioSplitRequest)
          .then(() => {
            reactQueryClient.refetchQueries({
              queryKey: ['getPortfoliosBeingProcessed'],
            })
            enqueueSnackbar(
              'Portfolio has been split successfully',
              notifySuccess
            )
          })
          .catch(() => {
            enqueueSnackbar('Failed to split the portfolio', notifyError)
          })
          .finally(() => {
            setOpenSplitDialog(false)
            setPortfolioSplitRequest(undefined)
          })
      }
    },
    { enabled: !!portfolioSplitRequest }
  )

  const handleSplit = () => {
    const validationMessage = validateSubmitMessage()

    if (validationMessage) {
      enqueueSnackbar(validationMessage, notifyError)
      return
    }

    const commonRequestData = {
      stagingId,
      sellerId,
      fileName,
      assetTypes,
      totalNumberOfAccounts,
      strategy: splitType.toString(),
      splitDateField: splitDateType,
      splitParams:
        splitDateRange === PortfolioSplitDateRangeEnum.Custom ||
        isFaceValueSplit
          ? splitOptions
          : [],
      splitDateRange,
      templateId,
      cutOffDateUtc: cutOffDate!,
      accountBreakupParameter: splitAccountBreakupParameter,
      accountBreakupType: splitAccountBreakupType,
    }

    if (shouldShowSimulateSplit) {
      setSimulateSplitRequest(commonRequestData)
      setShowSimulateSplit(true)
    } else {
      setPortfolioSplitRequest(commonRequestData)
    }
  }

  const getSplitText = () => {
    return splitType === SplitTypesEnum.Random
      ? 'Number of Files'
      : 'Number of Date Segments'
  }

  const titleText = (title: string) => {
    return (
      <Box fontWeight={600} paddingTop={4}>
        {title}:{' '}
      </Box>
    )
  }

  const updateSplitOptionsAtIndex = (
    index: number,
    updates: Partial<SplitOption>
  ) => {
    const updatedSplitOptions = [...splitOptions]
    const selectedSplitOptions = updatedSplitOptions[index]

    for (const key in updates) {
      if (key) {
        const castedKey = key as keyof SplitOption
        selectedSplitOptions[castedKey] = updates[castedKey] as any
      }
    }

    setSplitOptions(updatedSplitOptions)
  }

  const standardDates = (index: number, isFromDate: boolean) => {
    const indexFromDate = splitOptions[index].fromDate
    const indexToDate = splitOptions[index].toDate

    if (isFromDate) {
      if (indexToDate && !moment(indexToDate).isAfter(indexFromDate)) {
        updateSplitOptionsAtIndex(index, {
          toDate: moment(indexFromDate).add(1, 'days').toDate(),
        })
      }
    } else if (indexFromDate && !moment(indexToDate).isAfter(indexFromDate)) {
      updateSplitOptionsAtIndex(index, {
        fromDate: moment(indexToDate).subtract(1, 'days').toDate(),
      })
    }

    for (let i = index; i > 0; i--) {
      const previousIndex = i - 1
      const previousFromDate = splitOptions[previousIndex].fromDate
      const previousToDate = splitOptions[previousIndex].toDate
      const currentFromDate = splitOptions[i].fromDate

      if (
        previousToDate &&
        moment(previousToDate).diff(currentFromDate) !== 1
      ) {
        const newToDate = moment(currentFromDate).subtract(1, 'days')
        const newFromDate =
          previousFromDate && !newToDate.isAfter(previousFromDate)
            ? newToDate.clone().subtract(1, 'days')
            : undefined

        const params: any = {
          toDate: newToDate.toDate(),
        }

        if (newFromDate) {
          params.fromDate = newFromDate.toDate()
        }

        updateSplitOptionsAtIndex(previousIndex, { ...params })
      }
    }

    for (let i = index; i < splitOptions.length - 1; i++) {
      const nextIndex = i + 1
      const nextFromDate = splitOptions[nextIndex].fromDate
      const nextToDate = splitOptions[nextIndex].toDate
      const currentToDate = splitOptions[i].toDate

      if (nextFromDate && moment(currentToDate).diff(nextFromDate) !== 1) {
        const newFromDate = moment(currentToDate).add(1, 'days')
        const newToDate =
          nextToDate && newFromDate.isSameOrAfter(nextToDate)
            ? newFromDate.clone().add(1, 'days')
            : undefined

        const params: any = {
          fromDate: newFromDate.toDate(),
        }

        if (newToDate) {
          params.toDate = newToDate?.toDate()
        }

        updateSplitOptionsAtIndex(nextIndex, { ...params })
      }
    }
  }

  const validateSubmitMessage = (): string | undefined => {
    if (
      splitType === SplitTypesEnum.Date &&
      splitDateRange === PortfolioSplitDateRangeEnum.Custom
    ) {
      const hasMissingFromDate = splitOptions
        .slice(1)
        .some((option) => !option.fromDate)
      const hasMissingToDate = splitOptions
        .slice(0, -1)
        .some((option) => !option.toDate)

      if (hasMissingFromDate || hasMissingToDate) {
        return 'When split by date is selected, all dates are required.'
      }
    }

    if (
      isFaceValueSplit &&
      splitAccountBreakupType === SplitAccountBreakupTypeEnum.Percentage
    ) {
      if (!isPercentageValid()) {
        return 'The sum of all percentages must be exactly 100%.'
      }

      if (hasMissingOrZeroPercentage()) {
        return 'Each split must have a percentage greater than 0%.'
      }
    }

    if (
      isFaceValueSplit &&
      splitAccountBreakupType === SplitAccountBreakupTypeEnum.TotalAmount
    ) {
      if (!isTotalAmountValid()) {
        return `The sum of all total amount must be exactly ${fNumberCurrency(
          totalFaceValue
        )}.`
      }

      if (hasMissingOrZeroTotalAmount()) {
        return 'Each split must have a total amount greater than 0.'
      }
    }

    return undefined
  }

  const titleBreakpoint = splitType === SplitTypesEnum.Random ? 3.5 : 3.2
  const valueBreakpoint = splitType === SplitTypesEnum.Random ? 8.5 : 8.8
  const valueTemplateBreakpoint =
    splitType === SplitTypesEnum.Random &&
    splitAccountBreakupParameter === SplitAccountBreakupParameterEnum.FaceValue
      ? 8
      : 10

  const isPercentageValid = () => {
    const totalPercentage = splitOptions.reduce(
      (sum, option) => sum + (option.percentage ?? 0),
      0
    )

    return totalPercentage === 1
  }

  const hasMissingOrZeroPercentage = () => {
    return splitOptions.some((option) => !option.percentage)
  }

  const isTotalAmountValid = () => {
    const totalAmount = splitOptions.reduce(
      (sum, option) => sum + (option.totalAmount ?? 0),
      0
    )

    return totalAmount === totalFaceValue
  }

  const hasMissingOrZeroTotalAmount = () => {
    return splitOptions.some((option) => !option.totalAmount)
  }

  const isFaceValueSplit =
    splitType === SplitTypesEnum.Random &&
    splitAccountBreakupParameter === SplitAccountBreakupParameterEnum.FaceValue

  const differenceTooltipTitle =
    splitAccountBreakupType === SplitAccountBreakupTypeEnum.Percentage
      ? 'This difference indicates the remaining percentage to reach 100%. A negative value means the total exceeds 100%.'
      : 'This difference shows the remaining amount required to match the total face value. A negative value means the total exceeds the total face value.'

  const shouldShowSimulateSplit = !showSimulateSplit && isFaceValueSplit

  const getDifference = () => {
    if (splitAccountBreakupType === SplitAccountBreakupTypeEnum.Percentage) {
      const setValues = splitOptions.reduce(
        (sum, x) => sum + (x.percentage || 0),
        0
      )

      const percentageValue = fCalcPercentToNumber(setValues)
      return `${100 - (percentageValue ?? 0)}%`
    }

    const setValues = splitOptions.reduce(
      (sum, x) => sum + (x.totalAmount || 0),
      0
    )
    const difference = totalFaceValue - setValues
    return fNumberCurrency(difference, profileClient?.Country)
  }

  return (
    <Grid container>
      {showSimulateSplit ? (
        <SimulateSplitPortfolio
          templates={templates}
          portfolioSplitRequest={simulateSplitRequest}
          splitAccountBreakupType={splitAccountBreakupType}
          splitOptions={splitOptions}
          forwardFlowMaxFaceValue={forwardFlowMaxFaceValue}
          setShowSimulateSplit={setShowSimulateSplit}
        />
      ) : (
        <>
          <Grid item xs={titleBreakpoint}>
            {titleText('Method')}
          </Grid>
          <Grid item xs={valueBreakpoint}>
            <Box data-cy="portfolio-split-method" pt="2px">
              <TextField select fullWidth value={splitType}>
                {splitTypes?.map((item: any) => (
                  <MenuItem
                    data-cy={`portfolio-split-method-${item.id}`}
                    key={item.id}
                    value={item.id ?? ''}
                    selected={item.id === splitType}
                    onClick={() => setSplitType(item.id)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          {splitType === SplitTypesEnum.Date && (
            <>
              <Grid item xs={titleBreakpoint}>
                {titleText('Date Option')}
              </Grid>
              <Grid item xs={valueBreakpoint}>
                <Box data-cy="portfolio-split-date-option" pt="2px">
                  <TextField select fullWidth value={splitDateRange}>
                    {splitDataRangeTypes?.map((item: any) => (
                      <MenuItem
                        data-cy={`portfolio-split-date-option-${item.id}`}
                        key={item.id}
                        value={item.id ?? ''}
                        selected={item.id === splitDateRange}
                        onClick={() => setSplitDateRange(item.id)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
            </>
          )}
          {splitType === SplitTypesEnum.Random && (
            <>
              <Grid item xs={titleBreakpoint}>
                {titleText('Account Breakup Parameter')}
              </Grid>
              <Grid item xs={valueBreakpoint}>
                <Box
                  data-cy="portfolio-account-breakup-parameter-option"
                  pt="2px"
                >
                  <TextField
                    select
                    fullWidth
                    value={splitAccountBreakupParameter}
                  >
                    {splitAccountBreakupParameterTypes?.map((item: any) => (
                      <MenuItem
                        data-cy={`portfolio-account-breakup-parameter-option-${item.id}`}
                        key={item.id}
                        value={item.id ?? ''}
                        selected={item.id === splitAccountBreakupParameter}
                        onClick={() => setSplitAccountBreakupParameter(item.id)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              {isFaceValueSplit && (
                <>
                  <Grid item xs={titleBreakpoint}>
                    {titleText('Account Breakup Type')}
                  </Grid>
                  <Grid item xs={valueBreakpoint}>
                    <Box
                      data-cy="portfolio-account-breakup-type-option"
                      pt="2px"
                    >
                      <TextField
                        select
                        fullWidth
                        value={splitAccountBreakupType}
                      >
                        {splitAccountBreakupTypes?.map((item: any) => (
                          <MenuItem
                            data-cy={`portfolio-account-breakup-type-option-${item.id}`}
                            key={item.id}
                            value={item.id ?? ''}
                            selected={item.id === splitAccountBreakupType}
                            onClick={() => setSplitAccountBreakupType(item.id)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item xs={titleBreakpoint}>
            {titleText('File Name')}
          </Grid>
          <Grid item xs={valueBreakpoint}>
            <Box data-cy="portfolio-split-file-name" paddingTop={4}>
              {fileName}
            </Box>
          </Grid>
          <Grid item xs={titleBreakpoint}>
            {titleText('Asset Type')}
          </Grid>
          <Grid item xs={valueBreakpoint}>
            <Box data-cy="portfolio-split-asset-type" paddingTop={4}>
              {assetTypes}
            </Box>
          </Grid>
          <Grid item xs={titleBreakpoint}>
            {titleText('Total Accounts')}
          </Grid>
          <Grid item xs={valueBreakpoint}>
            <Box data-cy="portfolio-total-number-of-accounts" paddingTop={4}>
              {totalNumberOfAccounts}
            </Box>
          </Grid>
          {splitType === SplitTypesEnum.Date && (
            <>
              <Grid item xs={titleBreakpoint}>
                {titleText('Date Field')}
              </Grid>
              <Grid item xs={valueBreakpoint}>
                <Box data-cy="portfolio-split-date-field" pt="2px">
                  <TextField select fullWidth value={splitDateType}>
                    {dateSplitTypes?.map((item: any) => (
                      <MenuItem
                        data-cy={`portfolio-split-date-field-${item.id}`}
                        key={item.id}
                        value={item.id ?? ''}
                        selected={item.id === splitDateType}
                        onClick={() => setSplitDateType(item.id)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
            </>
          )}
          {splitType === SplitTypesEnum.Date &&
          splitDateRange !== PortfolioSplitDateRangeEnum.Custom ? (
            <>
              <Grid item xs={titleBreakpoint}>
                {titleText('Template Id')}
              </Grid>
              <Grid item xs={valueBreakpoint} pt="2px">
                <TextField
                  select
                  fullWidth
                  onChange={(e: any) => setTemplateId(e.target.value)}
                  value={templateId}
                >
                  {templates?.map((item: any) => (
                    <MenuItem
                      data-cy={`portfolio-split-template-${item.id}`}
                      key={item.id}
                      value={Number(item.id) || ''}
                      selected={
                        item.id && Number(item.id) === selectedTemplateId
                      }
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={titleBreakpoint}>
                {titleText(getSplitText())}
              </Grid>
              <Grid item xs={valueBreakpoint}>
                <Box data-cy="portfolio-split-number" paddingTop={2}>
                  <TextField
                    value={numberOfSplits}
                    type="number"
                    inputProps={{ min: 2, max: 10 }}
                    onChange={(e) => {
                      let num = Number(e.target.value)
                      if (num > 10) num = 10
                      if (num < 2) num = 2
                      while (splitOptions.length < num) {
                        splitOptions.push({ templateId: selectedTemplateId })
                      }
                      while (splitOptions.length > num) {
                        splitOptions.pop()
                      }
                      setNumberOfSplits(num)
                    }}
                  />
                </Box>
              </Grid>
              {isFaceValueSplit &&
                forwardFlowMaxFaceValue &&
                forwardFlowMaxFaceValue > 0 && (
                  <>
                    <Grid item xs={titleBreakpoint}>
                      {titleText('Forward Flow Max Face Value')}
                    </Grid>
                    <Grid item xs={valueBreakpoint} pt={4}>
                      {fNumberCurrency(
                        forwardFlowMaxFaceValue,
                        profileClient?.Country
                      )}
                    </Grid>
                  </>
                )}
              {isFaceValueSplit && (
                <>
                  <Grid item xs={titleBreakpoint}>
                    {titleText('Total Face Value')}
                  </Grid>
                  <Grid item xs={4.5} pt={4}>
                    {fNumberCurrency(totalFaceValue, profileClient?.Country)}
                  </Grid>
                  <Grid item xs={1.5}>
                    {titleText('Difference')}
                  </Grid>
                  <Grid item xs={2.4} pt={4} display="flex">
                    {getDifference()}
                    <Flex pl={1} pb="1px" alignItems="flex-end">
                      <Tooltip title={differenceTooltipTitle}>
                        <Icon
                          name="InfoOutlined"
                          fontSize="14px"
                          color={Colors.primary}
                        />
                      </Tooltip>
                    </Flex>
                  </Grid>
                </>
              )}
              {[...Array(numberOfSplits)].map((x: number, i: number) => (
                <Grid item xs={12} key={i}>
                  <Box borderTop="1px solid #ccc" mt="20px">
                    {splitType === SplitTypesEnum.Date && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="end"
                        pt="4px"
                      >
                        <Grid item xs={2}>
                          {titleText('From')}
                        </Grid>
                        <Grid item xs={4}>
                          {i === 0 ? (
                            <Box display="flex" alignItems="end">
                              <Icon name="FirstPage" />
                            </Box>
                          ) : (
                            <DatePicker
                              value={splitOptions[i].fromDate ?? null}
                              onChange={(date: Date | null) => {
                                updateSplitOptionsAtIndex(i, { fromDate: date })

                                if (date) {
                                  standardDates(i, true)
                                }
                              }}
                              country={profileClient?.Country}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          {titleText('To')}
                        </Grid>
                        <Grid item xs={4}>
                          {i === numberOfSplits - 1 ? (
                            <Box
                              display="flex"
                              alignItems="end"
                              justifyContent="start"
                              pl={14}
                            >
                              <Icon name="LastPage" />
                            </Box>
                          ) : (
                            <DatePicker
                              value={splitOptions[i].toDate ?? null}
                              onChange={(date: Date | null) => {
                                updateSplitOptionsAtIndex(i, { toDate: date })

                                if (date) {
                                  standardDates(i, false)
                                }
                              }}
                              country={profileClient?.Country}
                            />
                          )}
                        </Grid>
                      </Box>
                    )}
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      pt="4px"
                    >
                      <Grid item xs={2} pb={isFaceValueSplit ? '4px' : 0}>
                        {titleText(`Set #${i + 1}`)}
                      </Grid>
                      <Grid
                        item
                        xs={valueTemplateBreakpoint}
                        pb={isFaceValueSplit ? '2px' : 0}
                      >
                        <TextField
                          select
                          fullWidth
                          onChange={(e: any) =>
                            updateSplitOptionsAtIndex(i, {
                              templateId: e.target.value,
                            })
                          }
                          value={splitOptions[i].templateId}
                        >
                          {templates?.map((item: any) => (
                            <MenuItem
                              data-cy={`portfolio-split-template-${item.id}`}
                              key={item.id}
                              value={Number(item.id) || ''}
                              selected={
                                item.id &&
                                Number(item.id) === selectedTemplateId
                              }
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {isFaceValueSplit &&
                        splitAccountBreakupType ===
                          SplitAccountBreakupTypeEnum.Percentage && (
                          <Grid item xs={2} pb="3px">
                            <Box
                              data-cy="portfolio-split-percentage"
                              pt={2}
                              pl={3}
                            >
                              <TextField
                                value={
                                  fCalcPercentToNumber(
                                    splitOptions[i].percentage
                                  ) ?? null
                                }
                                type="number"
                                inputProps={{ min: 0, max: 100 }}
                                onChange={(e) => {
                                  updateSplitOptionsAtIndex(i, {
                                    percentage: Number(
                                      fCalcNumberToPercent(e.target?.value)
                                    ),
                                  })
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Grid>
                        )}
                      {isFaceValueSplit &&
                        splitAccountBreakupType ===
                          SplitAccountBreakupTypeEnum.TotalAmount && (
                          <Grid item xs={2}>
                            <Box
                              data-cy="portfolio-split-total-amount"
                              pt={2}
                              pl={3}
                            >
                              <CurrencyInput
                                variant="primary"
                                width="100%"
                                value={splitOptions[i].totalAmount}
                                onChange={(value) => {
                                  updateSplitOptionsAtIndex(i, {
                                    totalAmount: value
                                      ? Number(value)
                                      : undefined,
                                  })
                                }}
                                country={profileClient?.Country}
                              />
                            </Box>
                          </Grid>
                        )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </>
          )}
        </>
      )}

      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Box display="flex" flexDirection="row" paddingTop={4}>
              <Box marginRight={2} marginLeft={2}>
                <Button
                  data-cy="portfolio-split-cancel"
                  onClick={handleCancel}
                  variant="outlined"
                  color="primary"
                  startIcon={
                    loadingTeardownStagePortfolioSplit && (
                      <CircularProgress size={18} color="primary" />
                    )
                  }
                  disabled={loadingSplitPortfolio}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  data-cy="portfolio-split-submit"
                  onClick={handleSplit}
                  variant="contained"
                  color="primary"
                  startIcon={
                    loadingSplitPortfolio && (
                      <CircularProgress size={18} color="secondary" />
                    )
                  }
                  disabled={numberOfSplits < 2 || loadingSplitPortfolio}
                >
                  {shouldShowSimulateSplit ? 'Simulate' : 'Submit'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SplitPortfolio
