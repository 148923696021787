import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import { SaveSurveyAnswerRequest, SaveSurveyRequest } from './types'

export const SaveSurveyBusiness = async (
  businessIds: string[],
  surveyId: any
): Promise<any> => {
  const form = new FormData()
  form.append('SurveyId', surveyId.toString())
  businessIds?.forEach((businessId, index) => {
    form.append(`BusinessIds[${index}]`, businessId)
  })

  const response = await httpClient.post(
    urls.SurveyPostSaveSurveyBusiness,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return response?.data
}

export const DeleteSurveyBusiness = async (
  businessIds: string[],
  surveyId: any
): Promise<any> => {
  const form = new FormData()
  form.append('SurveyId', surveyId.toString())
  businessIds?.forEach((businessId, index) => {
    form.append(`BusinessIds[${index}]`, businessId)
  })

  const response = await httpClient.post(
    urls.SurveyPostDeleteSurveyBusiness,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return response?.data
}

export const archiveSurvey = async (
  surveyId: number | undefined
): Promise<any> => {
  const response = await httpClient.post(urls.SurveyArchiveSurvey, {
    surveyId,
  })
  return response?.data
}

export const cloneSurvey = async (
  surveyId: number | undefined
): Promise<any> => {
  const response = await httpClient.post(urls.SurveyCloneSurvey, {
    surveyId,
  })
  return response?.data
}

export const saveSurvey = async (request: SaveSurveyRequest): Promise<any> => {
  const response = await httpClient.post(urls.SaveSurvey, request)
  return response?.data
}

export const saveSurveyAssetType = async (
  request: SaveSurveyRequest
): Promise<any> => {
  const response = await httpClient.post(urls.SaveSurveyAssetType, request)
  return response?.data
}

export const deleteSurveyAssetType = async (
  request: SaveSurveyRequest
): Promise<any> => {
  const response = await httpClient.post(urls.DeleteSurveyAssettype, request)
  return response?.data
}

export const updateSurvey = async (id: number, name: string): Promise<any> => {
  const response = await httpClient.post(urls.SurveyUpdateSurvey, {
    id,
    name,
  })
  return response?.data
}

export const updateSurveySchema = async (
  id: number,
  schema: string
): Promise<any> => {
  const response = await httpClient.post(urls.SurveyUpdateSurveySchema, {
    id,
    schema,
  })
  return response?.data
}

export const cloneExternalSurvey = async (
  surveyId: number | undefined,
  businessId: string | undefined
): Promise<any> => {
  const response = await httpClient.post(urls.SurveyExternalCloneSurvey, {
    surveyId,
    businessId,
  })
  return response?.data
}

export const saveSurveyAnswer = async (
  request: SaveSurveyAnswerRequest
): Promise<any> => {
  const response = await httpClient.post(urls.SaveSurveyAnswer, request)
  return response?.data
}

export const deleteSurvey = async (
  surveyId: number | undefined
): Promise<any> => {
  const response = await httpClient.post(urls.DeleteSurvey, surveyId)
  return response?.data
}

export const updateSurveyAnswerStatus = async (
  surveyAnswerId: number,
  businessId: string,
  status: string
): Promise<any> => {
  const response = await httpClient.post(urls.UpdateSurveyAnswerStatus, {
    surveyAnswerId,
    businessId,
    status,
  })
  return response?.data
}

export const saveSurveyPdf = async (
  file: File | undefined,
  surveyId: number,
  businessId: string
): Promise<any> => {
  const form = new FormData()
  if (file) form.append('file', file)
  form.append('surveyId', surveyId.toString())
  form.append('businessId', businessId.toString())

  const response = await httpClient.post(urls.SaveSurveyPdf, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}

export const applyDssToPortfolioTemplate = async (
  surveyId: number,
  sellerUploadTemplateId: number
): Promise<any> => {
  const response = await httpClient.post(urls.ApplyDssToPortfolioTemplate, {
    surveyId,
    sellerUploadTemplateId,
  })
  return response?.data
}

export const applyDssToManyPortfolioTemplate = async (
  surveyId: number,
  sellerUploadTemplateIds: number[]
): Promise<any> => {
  const response = await httpClient.post(urls.ApplyDssToManyPortfolioTemplate, {
    surveyId,
    sellerUploadTemplateIds,
  })
  return response?.data
}
