/* eslint-disable react/jsx-indent */
import React from 'react'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { DataTable, ModalDialog, renderDateTime } from 'everchain-uilibrary'
import { getUnmaskedDataWorkflowLog } from 'src/data/features/get/portfolio/portfolio'
import { WorkflowLogResponse } from 'src/data/features/get/portfolio/types'

interface UnmaskedDataHistoryModalProps {
  workflowId?: number
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const UnmaskedDataHistoryModal: React.FC<UnmaskedDataHistoryModalProps> = ({
  workflowId,
  isOpen,
  setIsOpen,
}: UnmaskedDataHistoryModalProps) => {
  const { data: workflowLogData, isFetching: loadingWorkflowLogData } =
    useCustomQuery<WorkflowLogResponse[]>(
      ['getUnmaskedDataWorkflowLog', workflowId],
      async () => getUnmaskedDataWorkflowLog(workflowId!),
      { enabled: !!workflowId }
    )

  const gridColumns: any[] = [
    {
      title: 'Date',
      field: 'createdOn',
      show: true,
      width: 170,
      render: (props: any) => renderDateTime(props),
    },
    {
      title: 'User',
      field: 'userDisplayName',
      show: true,
      width: 200,
    },
    {
      title: 'Description',
      field: 'logDescription',
      show: true,
    },
  ]

  return (
    <>
      <ModalDialog
        header="Unmasked Data Request History"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        hideOkButton
        maxwidth="60%"
      >
        <DataTable
          maxHeight="54vh"
          data={workflowLogData}
          gridColumns={gridColumns}
          isLoading={loadingWorkflowLogData}
        />
      </ModalDialog>
    </>
  )
}

export default UnmaskedDataHistoryModal
