/* eslint-disable react/jsx-indent */
import React, { useContext, useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  Box,
  Button,
  CircularProgress,
  DataTable,
  Flex,
  Icon,
  IconButton,
  ModalDialog,
  renderDateTime,
  Typography,
} from 'everchain-uilibrary'
import {
  ActivityUnmaskDataFileResponse,
  RewindNDARequest,
} from 'src/data/features/post/portfolio/types'
import { getNDADocumentUriByFile } from 'src/data/features/get/portfolio/portfolio'
import { AuthContext } from 'src/context/AuthenticationContext'
import { INTERNAL } from 'src/utils/constants'
import { rewindNDAUnmaskedData } from 'src/data/features/post/portfolio/portfolio'
import { useQueryClient } from '@tanstack/react-query'
import { UnmaskDataActivities } from 'src/graphql/models/Portfolio'

interface ApproveNDADraftModalProps {
  workflowId?: number
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  activityUnmaskDataFiles?: ActivityUnmaskDataFileResponse[]
  refetchQueries?: string[]
  unmaskDataActivities?: UnmaskDataActivities
}

const notifyError = notistackOptions('error')
const notifySuccess = notistackOptions('success')

const UnmaskedDataNDAFilesModal: React.FC<ApproveNDADraftModalProps> = ({
  workflowId,
  isOpen,
  setIsOpen,
  activityUnmaskDataFiles,
  refetchQueries,
  unmaskDataActivities,
}: ApproveNDADraftModalProps) => {
  const [downloadFileId, setDownloadFileId] = useState<number>()
  const [rewindNDARequest, setRewindNDARequest] = useState<RewindNDARequest>()
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [unmaskDataFileId, setUnmaskDataFileId] = useState<number>()
  const { userPermissions } = useContext(AuthContext)

  const { enqueueSnackbar } = useSnackbar()
  const reactQueryClient = useQueryClient()

  const isInternal = userPermissions.type.toLowerCase() === INTERNAL

  const showRewind =
    isInternal &&
    !unmaskDataActivities?.denial &&
    !unmaskDataActivities?.approval &&
    !unmaskDataActivities?.executed

  const { isFetching: loadingUriFile } = useCustomQuery<any>(
    ['getNDADocumentUriByFile', downloadFileId],
    async () => {
      if (downloadFileId && workflowId) {
        await getNDADocumentUriByFile(workflowId, downloadFileId)
          .then((data: string) => {
            if (data) {
              window.location.href = data
            }
            return data
          })
          .catch(() => {
            enqueueSnackbar('Download file failed', notifyError)
          })
          .finally(() => {
            setDownloadFileId(undefined)
          })
      }
    },
    { enabled: !!downloadFileId }
  )

  const { isFetching: loadingRewindNDA } = useCustomQuery<any>(
    ['getNDADocumentUriByFile', downloadFileId],
    async () => {
      if (rewindNDARequest) {
        await rewindNDAUnmaskedData(rewindNDARequest)
          .then((data: string) => {
            enqueueSnackbar('NDA rewound', notifySuccess)

            if (refetchQueries) {
              reactQueryClient.refetchQueries({
                queryKey: refetchQueries,
              })
            }

            setIsOpen(false)
            setOpenConfirmModal(false)

            return data
          })
          .catch(() => {
            enqueueSnackbar('Rewind NDA unmasked data failed.', notifyError)
          })
          .finally(() => {
            setRewindNDARequest(undefined)
            setUnmaskDataFileId(undefined)
          })
      }
    },
    { enabled: !!rewindNDARequest }
  )

  const gridColumns: any[] = [
    {
      title: 'File Type',
      field: 'fileType',
      show: true,
    },
    {
      title: 'User Type',
      field: 'userType',
      show: true,
    },
    {
      title: 'Uploaded Date',
      field: 'uploadedUtc',
      show: true,
      render: (props: any) => renderDateTime(props),
    },
    {
      title: 'Download NDA',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>
            <Flex justifyContent="center">
              {downloadFileId === props.dataItem.id && loadingUriFile ? (
                <Box pt="2px" pb={2} pr={1}>
                  <CircularProgress width="20px" />
                </Box>
              ) : (
                <IconButton
                  onClick={() => setDownloadFileId(Number(props.dataItem.id))}
                >
                  <Icon name="Download" />
                </IconButton>
              )}
            </Flex>
          </td>
        )
      },
    },
    {
      title: 'Rewind',
      show: showRewind,
      render: (props: any) => {
        return (
          <td {...props}>
            <Flex justifyContent="center">
              {rewindNDARequest?.unmaskDataFileId === props.dataItem.id &&
              loadingRewindNDA ? (
                <Box pt="2px" pb={2} pr={1}>
                  <CircularProgress width="20px" />
                </Box>
              ) : (
                <Button
                  disabled={
                    activityUnmaskDataFiles?.[
                      activityUnmaskDataFiles.length - 1
                    ]?.id === props.dataItem.id
                  }
                  onClick={() => {
                    setUnmaskDataFileId(props.dataItem.id)
                    setOpenConfirmModal(true)
                  }}
                >
                  Rewind
                </Button>
              )}
            </Flex>
          </td>
        )
      },
    },
  ]

  return (
    <>
      <ModalDialog
        header="NDA Versions"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        hideOkButton
        maxwidth="60%"
      >
        {showRewind && (
          <Flex width="100%" pb={2} justifyContent="flex-end">
            <Button
              onClick={() => {
                setOpenConfirmModal(true)
              }}
              isFetching={rewindNDARequest?.rewindEntireNDA && loadingRewindNDA}
            >
              Rewind All
            </Button>
          </Flex>
        )}
        <DataTable
          maxHeight="54vh"
          data={activityUnmaskDataFiles}
          gridColumns={gridColumns}
        />
      </ModalDialog>
      <ModalDialog
        header="Rewind Confirmation"
        isOpen={openConfirmModal}
        onClose={() => {
          setOpenConfirmModal(false)
        }}
        onContinue={() => {
          if (unmaskDataFileId) {
            setRewindNDARequest({
              rewindEntireNDA: false,
              unmaskDataFileId: unmaskDataFileId,
              workflowId: workflowId!,
            })
          } else {
            setRewindNDARequest({
              rewindEntireNDA: true,
              workflowId: workflowId!,
            })
          }
        }}
        buttonOkText="Confirm"
        maxwidth="50%"
        isFetching={loadingRewindNDA}
      >
        <Typography variant="body1">
          {unmaskDataFileId ? (
            <>
              Are you sure you want to rewind? This action is irreversible. All
              files after this point will be permanently deleted and cannot be
              recovered.
            </>
          ) : (
            <>
              Are you sure you want to rewind all files? This action will remove
              all files and restart the process from the beginning. This
              operation is irreversible.
            </>
          )}
        </Typography>
      </ModalDialog>
    </>
  )
}

export default UnmaskedDataNDAFilesModal
