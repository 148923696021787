/* eslint-disable react/jsx-indent */
import React, { useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { GET_DOCUMENT_NDA_URI } from 'src/graphql/operations/queries/portfolio'
import { NDADocumentUri } from 'src/graphql/models/PortfolioDetail'
import { BusinessSelector } from 'src/components/BusinessSelector'
import { ConvertDateToUTC, MomentDateFormat } from 'src/utils/date'
import { BuyerInfo } from 'src/graphql/models/Portfolio'
import DTAccordion from 'src/components/Accordion'
import { Grid, Box, Typography, Button, Skeleton } from '@mui/material'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { AuthContext } from 'src/context/AuthenticationContext'
import moment from 'moment'
import {
  getBuyersAllowedAccessPortfolio,
  getUnmaskDataInfo,
} from 'src/data/features/get/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { PortfolioBid } from 'src/data/features/get/portfolio/types'
import { Group, Icon } from 'everchain-uilibrary'
import { useQueryClient } from '@tanstack/react-query'
import {
  requestUnmaskPortfolioData,
  uploadNDAFile,
} from 'src/data/features/post/portfolio/portfolio'
import {
  UnmaskedDataInfoResponse,
  UnmaskPortfolioDataRequest,
  UploadNDAFileRequest,
} from 'src/data/features/post/portfolio/types'
import { AgreementTemplateFeatureFlagResponse } from 'src/data/features/get/agreementTemplate/types'
import { getFeatureFlagAgreementTemplate } from 'src/data/features/get/agreementTemplate/agreementTemplate'
import ApproveNDADraftModal from './ApproveNDADraftModal'
import UnmaskedDataNDAFilesModal from './UnmaskedDataNDAFilesModal'
import UnmaskedDataHistoryModal from './UnmaskedDataHistoryModal'

interface UnmaskDataRequestProps {
  portfolioId: string | undefined
  buyerBidsData: PortfolioBid[] | undefined
  auctionClosed: boolean
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const UnmaskDataRequest: React.FC<UnmaskDataRequestProps> = ({
  portfolioId,
  buyerBidsData,
  auctionClosed,
}: UnmaskDataRequestProps) => {
  const [selectedBusiness, setSelectedBusiness] = useState<any>(undefined)
  const [disableUI, setDisableUI] = useState<boolean>(true)
  const [unmaskPortfolioDataRequest, setUnmaskPortfolioDataRequest] =
    useState<UnmaskPortfolioDataRequest>()
  const [uploadNDAFileRequest, setUploadNDAFileRequest] =
    useState<UploadNDAFileRequest>()
  const [approveNDADraftWorkflowId, setApproveNDADraftWorkflowId] =
    useState<number>()
  const [openApproveNDADraftModal, setOpenApproveNDADraftModal] =
    useState<boolean>(false)
  const [openFilesModal, setOpenFilesModal] = useState<boolean>(false)
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()
  const { profileClient } = useContext(AuthContext)
  const momentFormat = MomentDateFormat(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )
  const reactQueryClient = useQueryClient()

  const { data: unmaskDataInfo, isFetching: loadingUnmaskDataInfo } =
    useCustomQuery<UnmaskedDataInfoResponse>(
      ['getUnmaskDataInfo', portfolioId],
      async () => getUnmaskDataInfo(portfolioId),
      { enabled: true, cacheTime: 0 }
    )

  useCustomQuery<any>(
    ['requestUnmaskPortfolioData', unmaskPortfolioDataRequest],
    async () => {
      if (unmaskPortfolioDataRequest) {
        await requestUnmaskPortfolioData(unmaskPortfolioDataRequest)
          .then(() => {
            enqueueSnackbar(
              'Unmask data request has been submitted',
              notifySuccess
            )

            reactQueryClient.refetchQueries({
              queryKey: ['getUnmaskDataInfo'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Unmask data request failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setUnmaskPortfolioDataRequest(undefined)
          })
      }
    },
    { enabled: !!unmaskPortfolioDataRequest }
  )

  const buyerFinalNDA = unmaskDataInfo?.activityUnmaskDataFile
    ?.filter(
      (item) => item.userType === 'buyer' && item.fileType === 'nda-final'
    )
    .sort((a, b) => b.id - a.id)[0]

  const notifyWarning = notistackOptions('warning')
  const MAX_SIZE_FILE = 104857600 // 100Mb

  const { isFetching: fileUploading } = useCustomQuery<any>(
    ['uploadNDAFile', uploadNDAFileRequest],
    async () => {
      if (uploadNDAFileRequest) {
        await uploadNDAFile(uploadNDAFileRequest)
          .then(() => {
            enqueueSnackbar('File uploaded successfully', notifySuccess)

            reactQueryClient.refetchQueries({
              queryKey: ['getUnmaskDataInfo'],
            })
          })
          .catch(() => {
            enqueueSnackbar(
              'Error while trying to upload the file',
              notifyError
            )
          })
          .finally(() => {
            setUploadNDAFileRequest(undefined)
          })
      }
    },
    { enabled: !!uploadNDAFileRequest }
  )

  const handleFileRejected = () => {
    enqueueSnackbar('The file file exceeds 100MB limit', notifyWarning)
  }

  const handleFileUpload = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length) {
      setUploadNDAFileRequest({
        workflowId: unmaskDataInfo?.workflowId,
        file: acceptedFiles[0],
        fileType: isNDADraft ? 'NDADraft' : 'NDAFinal',
      })
    }
  }

  const { data: featureFlagAgreementTemplate } =
    useCustomQuery<AgreementTemplateFeatureFlagResponse>(
      ['getFeatureFlagAgreementTemplate', portfolioId],
      async () => getFeatureFlagAgreementTemplate(portfolioId),
      {
        cacheTime: 0,
        enabled: true,
      }
    )

  const { data: buyerQueryData, isFetching: loadingBuyerData } = useCustomQuery<
    BuyerInfo[]
  >(
    ['getBuyersAllowedAccessPortfolio', portfolioId],
    async () => getBuyersAllowedAccessPortfolio(portfolioId),
    { enabled: true, cacheTime: 0 }
  )

  useEffect(() => {
    if (buyerQueryData?.length === 1) {
      setSelectedBusiness(buyerQueryData[0])
      setDisableUI(false)
    }
  }, [buyerQueryData])

  const onSubmitUnmaskData = () => {
    setDisableUI(true)
    setUnmaskPortfolioDataRequest({
      portfolioId: portfolioId!,
      buyerId: selectedBusiness.id,
    })
  }

  const handleBusinessSelected = (business: any) => {
    setSelectedBusiness(business)
    setDisableUI(false)
  }
  const [getUri, { loading: ndaLoading }] = useLazyQuery<NDADocumentUri>(
    GET_DOCUMENT_NDA_URI,
    {
      onCompleted: (data) => {
        const uri = data?.getNDADocumentUri ?? ''
        if (uri === '') return
        window.location.href = uri
      },
    }
  )

  const onDownload = () => {
    getUri({
      variables: {
        workflowId: unmaskDataInfo?.workflowId,
      },
    })
  }

  const renderDownloadButton = () => {
    if (unmaskDataInfo?.ndaUnmaskDataRequested)
      return (
        <Grid item>
          <Button
            disableRipple={true}
            variant="contained"
            color="primary"
            disabled={ndaLoading}
            onClick={() => {
              onDownload()
            }}
            size="small"
          >
            {isNDADraft ? 'Download NDA Draft' : 'Download NDA Document'}
          </Button>
        </Grid>
      )

    return <></>
  }

  const renderNDAVersionsButton = () => {
    if (
      unmaskDataInfo?.activityUnmaskDataFile &&
      unmaskDataInfo.activityUnmaskDataFile.length > 0
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenFilesModal(true)}
          size="small"
        >
          NDA Versions
        </Button>
      )
    }

    return <></>
  }

  const renderHistoryButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenHistoryModal(true)}
        size="small"
      >
        History
      </Button>
    )
  }

  const newNDAEnabled = featureFlagAgreementTemplate?.enableNDAAgreementTemplate
  const isNDADraft =
    unmaskDataInfo?.currentState === 'Pending Buyer Review' && newNDAEnabled

  const fileAccept = isNDADraft
    ? '.docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
    : '.pdf, application/pdf'

  const ndaFileDescription = isNDADraft
    ? 'NDA Draft File (*.docx)'
    : 'Executed NDA File (*.pdf)'

  if (loadingUnmaskDataInfo || loadingBuyerData) {
    return <Skeleton variant="rectangular" width="100%" height={50} />
  }

  return !unmaskDataInfo &&
    ((buyerBidsData && buyerBidsData.length > 0) || auctionClosed) ? (
    <></>
  ) : (
    <DTAccordion
      id="unmask-data-accordion"
      title="Request Additional Unmasked Data"
      icon={<Icon name="VisibilityOff" />}
    >
      {unmaskDataInfo?.ndaUnmaskDataRequested &&
      (unmaskDataInfo.currentState === 'Pending Buyer Review' ||
        unmaskDataInfo.currentState === 'Pending Buyer Signature') ? (
        <Box display="flex" flexDirection="column" p={4}>
          {buyerFinalNDA?.uploadedBy ? (
            <>
              <Box fontSize="0.9rem">
                {`NDA submitted by: ${unmaskDataInfo?.ndaBuyerCopyUploadedBuyerName}`}
              </Box>
              <Box fontSize="0.9rem">
                {`NDA submitted on: ${
                  buyerFinalNDA?.uploadedUtc
                    ? moment(
                        new Date(ConvertDateToUTC(buyerFinalNDA?.uploadedUtc))
                      )?.format(momentFormat)
                    : 'N/A'
                }`}
              </Box>
              <Box fontSize="0.9rem"> Status: Pending NDA review</Box>
            </>
          ) : (
            <>
              <Box fontSize="0.9rem">
                {`NDA requested on:  ${
                  unmaskDataInfo?.ndaRequestedDateTime
                    ? moment(
                        new Date(
                          ConvertDateToUTC(unmaskDataInfo?.ndaRequestedDateTime)
                        )
                      )?.format(momentFormat)
                    : 'N/A'
                }
               `}
              </Box>
              <Box fontSize="0.9rem">
                Status:{' '}
                {newNDAEnabled ? unmaskDataInfo.currentState : 'Pending NDA'}
              </Box>
            </>
          )}
          <Group mt="5px">
            {renderDownloadButton()}
            {renderNDAVersionsButton()}
            {renderHistoryButton()}
            {newNDAEnabled &&
              unmaskDataInfo.currentState === 'Pending Buyer Review' && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={ndaLoading}
                  onClick={() => {
                    setApproveNDADraftWorkflowId(unmaskDataInfo.workflowId)
                    setOpenApproveNDADraftModal(true)
                  }}
                  size="small"
                >
                  Approve NDA Draft
                </Button>
              )}
          </Group>
          <Grid container spacing={4} style={{ marginTop: '5px' }}>
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <UploadDragDrop
                size="small"
                linkText={buyerFinalNDA?.uploadedBy ? 'Replace' : 'Upload'}
                disabled={fileUploading}
                uploading={fileUploading}
                maxSize={MAX_SIZE_FILE}
                onDropRejected={handleFileRejected}
                accept={fileAccept}
                text={ndaFileDescription}
                onDrop={(acceptedFiles) => {
                  handleFileUpload(acceptedFiles)
                }}
              />
              <Typography variant="caption" display="block" gutterBottom>
                Maximum allowed file size is <strong>100MB</strong>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" p={4}>
          <Box marginBottom={5}>
            <Typography variant="caption">
              The seller allows buyers to request additional unmasked data. This
              includes the consumer first and last names, address and VIN (when
              applicable). The masked data file will contain the additional
              unmasked data when the request is approved by the seller.
            </Typography>
          </Box>
          {unmaskDataInfo ? (
            <>
              {buyerQueryData && buyerQueryData?.length > 1 && (
                <Box fontSize="0.9rem">
                  Buyer name: {unmaskDataInfo?.buyerName}
                </Box>
              )}
              <Box fontSize="0.9rem">
                Request submitted by: {unmaskDataInfo?.submittedBy}
              </Box>
              <Box fontSize="0.9rem">
                Request submitted on:{' '}
                {unmaskDataInfo?.submittedOn
                  ? moment(
                      new Date(ConvertDateToUTC(unmaskDataInfo?.submittedOn))
                    )?.format(momentFormat)
                  : 'N/A'}
              </Box>
              <Box fontSize="0.9rem">
                Request status: {unmaskDataInfo?.currentState}
              </Box>
              <Group mt="5px">
                {renderDownloadButton()}
                {renderNDAVersionsButton()}
                {renderHistoryButton()}
              </Group>
            </>
          ) : (
            <>
              <Box display="flex" flexDirection="column">
                {buyerQueryData && buyerQueryData?.length > 1 && (
                  <Box p={1}>
                    <BusinessSelector
                      data={buyerQueryData}
                      disabled={selectedBusiness && disableUI}
                      label="Buyer"
                      noDataText="This user doesn't belong to any buyer"
                      selectionText="Select a buyer"
                      value={selectedBusiness || undefined}
                      onSelectedBusiness={(business) => {
                        handleBusinessSelected(business)
                      }}
                    />
                  </Box>
                )}
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(): void => onSubmitUnmaskData()}
                    disabled={
                      disableUI || (buyerBidsData && buyerBidsData.length > 0)
                    }
                  >
                    Submit a Request
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
      <ApproveNDADraftModal
        isOpen={openApproveNDADraftModal}
        setIsOpen={setOpenApproveNDADraftModal}
        refetchQueries={['getUnmaskDataInfo']}
        workflowId={approveNDADraftWorkflowId}
        setDisableUI={setDisableUI}
      />
      <UnmaskedDataNDAFilesModal
        isOpen={openFilesModal}
        setIsOpen={setOpenFilesModal}
        workflowId={unmaskDataInfo?.workflowId}
        activityUnmaskDataFiles={unmaskDataInfo?.activityUnmaskDataFile}
      />
      <UnmaskedDataHistoryModal
        isOpen={openHistoryModal}
        setIsOpen={setOpenHistoryModal}
        workflowId={unmaskDataInfo?.workflowId}
      />
    </DTAccordion>
  )
}

export default UnmaskDataRequest
