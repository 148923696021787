import styled from '@emotion/styled'
import { breakpoints, spacing } from 'everchain-uilibrary'

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  ${breakpoints.up('sm')} {
    justify-self: end;
    align-self: center;
  }
  .item {
    margin-left: ${spacing(8)};
    &:first-child {
      margin-left: 0;
    }
  }
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
  ${breakpoints.up('sm')} {
    grid-template-columns: 2fr 2fr;
  }
`
export const HeaderInfos = styled.div`
  display: flex;
  justify-content: center;
  justify-self: flex-start;
  align-items: center;
  > .btn-goback {
    align-self: flex-start;
  }
`
