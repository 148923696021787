/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { Typography } from '@mui/material'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ModalDialog } from 'everchain-uilibrary'
import { useQueryClient } from '@tanstack/react-query'
import { approveNDADraftUnmaskedData } from 'src/data/features/post/portfolio/portfolio'

interface ApproveNDADraftModalProps {
  workflowId?: number
  refetchQueries?: string[]
  setDisableUI: (disabled: boolean) => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const ApproveNDADraftModal: React.FC<ApproveNDADraftModalProps> = ({
  workflowId,
  refetchQueries,
  setDisableUI,
  isOpen,
  setIsOpen,
}: ApproveNDADraftModalProps) => {
  const [approveNDADraftWorkflowId, setApproveNDADraftWorkflowId] =
    useState<number>()

  const { enqueueSnackbar } = useSnackbar()
  const reactQueryClient = useQueryClient()

  const { isFetching: loading } = useCustomQuery<any>(
    ['approveNDADraftUnmaskedData', approveNDADraftWorkflowId],
    async () => {
      if (approveNDADraftWorkflowId) {
        await approveNDADraftUnmaskedData(approveNDADraftWorkflowId)
          .then(() => {
            enqueueSnackbar('NDA Draft approved', notifySuccess)

            reactQueryClient.refetchQueries({
              queryKey: refetchQueries,
            })
            setIsOpen(false)
          })
          .catch(() => {
            enqueueSnackbar('Approval NDA Draft failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setApproveNDADraftWorkflowId(undefined)
          })
      }
    },
    { enabled: !!approveNDADraftWorkflowId }
  )

  return (
    <ModalDialog
      header="Approve NDA Draft"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
        setDisableUI(false)
      }}
      onContinue={() => setApproveNDADraftWorkflowId(workflowId)}
      buttonOkText="Confirm"
      isFetching={loading}
      maxwidth="60%"
    >
      <Typography>
        Please review the Non-Disclosure Agreement (NDA) draft. If you have any
        questions or need further adjustments, you may cancel this approval and
        upload a new NDA draft file for the review.
      </Typography>
      <Typography pt={2}>
        Once you are ready to proceed, please confirm your approval of this
        draft.
      </Typography>
    </ModalDialog>
  )
}

export default ApproveNDADraftModal
