/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { Typography } from '@mui/material'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ModalDialog } from 'everchain-uilibrary'
import { useQueryClient } from '@tanstack/react-query'
import { denyUnmaskedDataRequest } from 'src/data/features/post/portfolio/portfolio'

interface DenyUnmaskedRequestModalProps {
  workflowId?: number
  setDisableUI: (disabled: boolean) => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const DenyUnmaskedRequestModal: React.FC<DenyUnmaskedRequestModalProps> = ({
  workflowId,
  setDisableUI,
  isOpen,
  setIsOpen,
}: DenyUnmaskedRequestModalProps) => {
  const [denyUnmaskedWorkflowId, setDenyUnmaskedWorkflowId] = useState<number>()

  const { enqueueSnackbar } = useSnackbar()
  const reactQueryClient = useQueryClient()

  const { isFetching: loading } = useCustomQuery<any>(
    ['denyUnmaskedDataRequest', denyUnmaskedWorkflowId],
    async () => {
      if (denyUnmaskedWorkflowId) {
        await denyUnmaskedDataRequest(denyUnmaskedWorkflowId)
          .then(() => {
            enqueueSnackbar('Request denied', notifySuccess)
            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
            setIsOpen(false)
          })
          .catch(() => {
            enqueueSnackbar('Denial failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setDenyUnmaskedWorkflowId(undefined)
          })
      }
    },
    { enabled: !!denyUnmaskedWorkflowId }
  )

  return (
    <ModalDialog
      header="Reject Unmasked Data Request"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
        setDisableUI(false)
      }}
      onContinue={() => setDenyUnmaskedWorkflowId(workflowId)}
      buttonOkText="Confirm"
      isFetching={loading}
      maxwidth="60%"
    >
      <Typography>
        You are about to reject the request for the unmasked data file. By
        confirming this action, access to the unmasked data will not be granted
        to the buyer, and the request will be marked as denied.
      </Typography>
      <Typography pt={2}>
        If you’re sure you want to proceed, please confirm.
      </Typography>
    </ModalDialog>
  )
}

export default DenyUnmaskedRequestModal
