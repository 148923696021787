/* eslint-disable react/require-default-props */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/self-closing-comp */
import React from 'react'
import { createPortal } from 'react-dom'

interface LoaderProps {
  specificHeight?: number | string | undefined
}
const Loader: React.FC<LoaderProps> = ({ specificHeight }) => {
  const gridContent =
    document && document.getElementsByClassName('accountgrid')[0]

  const loadingPanel = (
    <div
      className="k-loading-mask"
      style={{
        height: specificHeight
          ? specificHeight
          : gridContent
          ? gridContent.clientHeight
          : 200,
        position: 'absolute',
        top: '30%',
      }}
    >
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  )

  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel
}

export default Loader
