import { BACKEND_URL } from 'src/infra/api/axios-wrapper/httpClient'

const surveyPrefix = 'survey.'

export const SurveyPostSaveSurveyBusiness = `${BACKEND_URL}/${surveyPrefix}SaveSurveyBusiness`
export const SurveyPostDeleteSurveyBusiness = `${BACKEND_URL}/${surveyPrefix}DeleteSurveyBusiness`
export const SurveyArchiveSurvey = `${BACKEND_URL}/${surveyPrefix}archiveSurvey`
export const SaveSurvey = `${BACKEND_URL}/${surveyPrefix}SaveSurvey`
export const CloneSurvey = `${BACKEND_URL}/${surveyPrefix}CloneSurvey`
export const SaveSurveyAssetType = `${BACKEND_URL}/${surveyPrefix}SaveSurveyAssetType`
export const DeleteSurveyAssettype = `${BACKEND_URL}/${surveyPrefix}DeleteSurveyAssettype`
export const SurveySaveSurvey = `${BACKEND_URL}/${surveyPrefix}SaveSurvey`
export const SurveyCloneSurvey = `${BACKEND_URL}/${surveyPrefix}CloneSurvey`
export const SurveyUpdateSurvey = `${BACKEND_URL}/${surveyPrefix}UpdateSurvey`
export const SurveyUpdateSurveySchema = `${BACKEND_URL}/${surveyPrefix}UpdateSurveySchema`
export const SurveyExternalCloneSurvey = `${BACKEND_URL}/${surveyPrefix}CloneExternalSurvey`
export const SaveSurveyAnswer = `${BACKEND_URL}/${surveyPrefix}SaveSurveyAnswer`
export const DeleteSurvey = `${BACKEND_URL}/${surveyPrefix}DeleteSurvey`
export const UpdateSurveyAnswerStatus = `${BACKEND_URL}/${surveyPrefix}UpdateSurveyAnswerStatus`
export const SaveSurveyPdf = `${BACKEND_URL}/${surveyPrefix}SaveSurveyPdf`
export const ApplyDssToPortfolioTemplate = `${BACKEND_URL}/${surveyPrefix}ApplyDssToPortfolioTemplate`
export const ApplyDssToManyPortfolioTemplate = `${BACKEND_URL}/${surveyPrefix}ApplyDssToManyPortfolioTemplate`
