import { useQuery, QueryClient, UseQueryOptions } from '@tanstack/react-query'
import { useState } from 'react'

export const useCustomLazyQuery = <TData = unknown, TError = unknown>(
  key: any[],
  queryFn: (params: any) => Promise<TData | undefined>,
  options?: UseQueryOptions<TData | undefined, TError>
) => {
  const [trigger, setTrigger] = useState<any>(null)
  const [isFetching, setIsFetching] = useState(false)
  const query = useQuery<TData | undefined, TError>({
    queryKey: key,
    queryFn: () => {
      return queryFn(trigger)
    },
    enabled: false,
    ...options,
  })

  const executeQuery = async (params?: any) => {
    setIsFetching(true)
    await queryFn(params)
    setTrigger(params)
    setIsFetching(false)
  }

  return {
    ...query,
    executeQuery,
    isFetching,
  }
}

export const useCustomQuery = <TData = unknown, TError = unknown>(
  key: any[],
  queryFn: () => Promise<TData | undefined>,
  options?: UseQueryOptions<TData | undefined, TError>
) => {
  const queryOptions: UseQueryOptions<TData | undefined, TError> = {
    ...options,
  }

  const query = useQuery<TData | undefined, TError>({
    queryKey: key,
    queryFn: () => {
      return queryFn()
    },
    ...queryOptions,
  })

  return {
    ...query,
    refetchWithParams: (newParams: any) => query.refetch(newParams),
  }
}

export const useCustomQueryClient = async <TData, TError = unknown>(
  key: string[],
  queryFn: () => Promise<TData | undefined>,
  queryClient: QueryClient,
  options?: UseQueryOptions<TData | undefined, TError>
) => {
  const queryOptions: UseQueryOptions<TData | undefined, TError> = {
    ...options,
  }

  const result = queryClient.fetchQuery<TData | undefined, TError>(
    key,
    () => {
      return queryFn()
    },
    queryOptions
  )

  return result
}

export const refetchQueriesWrapper = (
  queryKeys: any[],
  queryClient: QueryClient
) => {
  queryKeys.forEach((queryKey) => {
    queryClient.refetchQueries({ queryKey: [queryKey] })
  })
}
